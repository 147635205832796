import { cn } from '@/lib/utils'
import React from 'react'

const Spinner = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'> & { innerDivClassName?: string }
>(({ className, innerDivClassName, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'relative w-5 h-5 rounded-full shadow-md bg-transparent',
      className,
    )}
    {...props}
  >
    <div
      className={cn(
        'absolute h-5 w-5 rounded-full border-2 border-t-primary animate-spin',
        className,
        innerDivClassName,
      )}
    />
  </div>
))
Spinner.displayName = 'Spinner'

export { Spinner }
